
//import ValidatorItem from '../components/validators/ValidatorItem'
//import classes from './AllValidators.module.css';
import Card from 'react-bootstrap/Card'
import Wizimage from "../components/graphics/StakewizImage"
import React, { Component } from 'react';
import ValidatorList from '../components/validators/ValidatorList'
import Moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import 'react-circular-progressbar/dist/styles.css';
import ClipLoader from 'react-spinners/ClipLoader';
import DisplaySolPrice from '../components/data/GetSolPrice';
class AllValidatorsPage extends Component {
    
    constructor(props) {
        super(props);
        this.setStateOfParent.bind(this);        
        this.state = { lastUpdate: "", epoch: "", epochSlotHeight: "", loadingInProgress: true, activating_stake: 0, deactivating_stake: 0};
    }
    
    setStateOfParent = (newTime, currentEpoch, currentEpochSlotHeight, loadingInProgress, activatingStake, deactivatingStake) => {
        this.setState({ lastUpdate: newTime, epoch: currentEpoch, epochSlotHeight: currentEpochSlotHeight, loadingInProgress: loadingInProgress, activating_stake: activatingStake, deactivating_stake: deactivatingStake });
    }
    
         

    render() {
        Moment.locale('en');
        const percentage = Math.floor((this.state.epochSlotHeight / 432000) * 100).toFixed(0);
        const remaining = 432000 - this.state.epochSlotHeight;
        const days_remaining = Math.floor((remaining) / 2 / 60 / 60 / 24)
        const hours_remaining = Math.floor((remaining / 2 / 60 / 60) - days_remaining * 24);
        const minutes_remaining = Math.floor((remaining / 2 / 60) - days_remaining * 24 * 60 - hours_remaining * 60);
        return (<section style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}>
            <Card className='main-card bg-light' >{/* style={{ marginTop: '30px', marginLeft: '30px', marginRight:'30px', padding: '20px', width: '95vw'}}> */}
                <Row style={{
                    marginBottom: '10px'}}>
                    <Col xs='12' md='12' style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'}}>
                    
                    
                        <h6>Cluster Infomation:</h6>
                        
                        {/* <p>We initially rank validators by their estimated APY based on data from the current epoch. </p> */}
                        
                    </Col>
                    
                    
                    <Col xs='12' md='4' style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>  
                        <div style={{ height: 75 }}>
                            <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}><b>Epoch time remaining:</b></div>
                            <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}>{days_remaining} days, {hours_remaining} hours, {minutes_remaining} minutes
                                </div>
                        </div>
                    </Col>
                    <Col xs='12' md='4' style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                    }}>      
                        <div style={{ width: 190, height: 75 , paddingRight: 15, paddingLeft: 15, textAlign: 'center'}}>
                        <b>Deactivating Stake:</b><br/>◎&nbsp;{this.state.deactivating_stake.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}                            
                        </div>
                        <div style={{ height: 75 }}>
                            <OverlayTrigger
                                key='epochtrig'
                                placement='top'
                                overlay={
                                    <Tooltip id={`tooltip-epoch`}>
                                        We are currently {percentage}% into epoch {this.state.epoch}
                                    
                                    
                    
                                    </Tooltip>
                                }
                                >
                                <div style={{ width: 50, height: 50 }}>
                                    <CircularProgressbar text={this.state.epoch} value={percentage} background backgroundPadding={6}
                                        styles={buildStyles({
                                            backgroundColor: "#05343C",                                        
                                            textColor: "#F0F0F0",
                                            pathColor: "#F0F0F0",
                                            trailColor: "transparent"
                                        })}/>
                                </div>
                                
                        
                            </OverlayTrigger>
                        </div>
                        <div style={{ width: 190, paddingRight: 15, paddingLeft: 15, height: 75, textAlign: 'center'}}>
                            <b>Activating Stake:</b><br/>◎&nbsp;{this.state.activating_stake.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        </div>


                        
                             
                    </Col>
                    <Col xs='12' md='4'  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                        justifyContent: 'center'
                                    
                        }}>
                            {this.state.loadingInProgress ? (
                            <div className="loader-container">
                                <ClipLoader color={'#05343C'} size={50} />
                            </div>
                        ) : (
                                <div style={{ height: 75 }}>
                                
                                    <DisplaySolPrice/>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                            }}>
                                            <b>Last Update:</b>&nbsp;<span className="updated_dt"> {Moment(this.state.lastUpdate).format('YYYY-MM-DD HH:mm:ss')}</span>
                                        
                                </div>
                            </div>
                                
                                    
                                    
                            
                        )}
                            
                        
                    </Col>
            
                 
                    </Row>
                <Card.Body>
                    < ValidatorList stateChanger={this.setStateOfParent} />
                </Card.Body>
                {/* <Card.Footer>
            <div className="d-flex justify-content-between">
                <div>
                    <p>We rank validators by their estimated APY from the current epoch.</p><p> Historical APY is a good indication as to how well a validator has performed over that period as it is affected by downtime / commission / network capibilities and many other factors.</p>
                </div>
                
            </div>
            </Card.Footer> */}
                <Card.Footer>
                    <div style={{
                        display: 'flex',
                        alignItems: 'right',
                        justifyContent: 'right',
                    }}>
                        <Wizimage />
                    </div>
                </Card.Footer>
            </Card>
        </section>
        );
    }
}


export default AllValidatorsPage;