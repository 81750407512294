import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom'

import "./custom.scss";
import './index.css';
//import 'bootstrap/dist/css/bootstrap.min.css';

import App from './App';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<BrowserRouter>
    <App tab="home" />
</BrowserRouter>);