import React from 'react'
import {  Routes, Route } from 'react-router-dom';

import AllValidatorsPage from './pages/AllValidators';
import FavouriteValidatorsPage from './pages/FavouriteValidators';
import NewValidatorsPage from './pages/NewValidators';

import MainNavigation from './components/layout/MainNavigation';

const viewportContext = React.createContext({});

const ViewportProvider = ({ children }) => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerHeight);
  const handleWindowResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <viewportContext.Provider value={{ width, height }}>
      {children}
    </viewportContext.Provider>
  );
};


function App() {
  return (
    <ViewportProvider>
       
    {<MainNavigation></MainNavigation> }
     <Routes>
        <Route path='/' element={<AllValidatorsPage />} />           
        <Route path='/favourites' element={<FavouriteValidatorsPage />} />               
        <Route path='/new' element={<NewValidatorsPage />} />               
      </Routes>
 
  </ViewportProvider>
  );
  
}

export default App;
