// import { Link } from 'react-router-dom';

import classes from './MainNavigation.module.css';
import Webimage from "../graphics/WebImage"
import LeapfrogImage from "../graphics/LeapfrogImage"
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// import Table from 'react-bootstrap/Table';

function MainNavigation() {
    return <header className={classes.header}>
        <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
            <Card className='main-card bg-light' > 
                <Row>
                <Col xs={12} md={3} >    
                <div style={{
                        display: 'flex',
                        alignItems: 'left',
                            justifyContent: 'left',
                            paddingTop: '25px'
                        }}>                          
                 
                        
                       
                    </div>  
                
               
                        
                 
                </Col>
                <Col xs={12} md={6}>
            
                    
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'}}>
                        <span>
                        <Webimage />
                        </span>
                        <span>
                            <h1 className='main-title'>
                                &nbsp;
                                Top Validators
                            
                                
                            </h1>
                        </span>
                    </div>  
                </Col>
                <Col xs={12} md={3} >    
                <div style={{
                        display: 'flex',
                        alignItems: 'center',
                            justifyContent: 'center',
                        paddingTop: '8px'}}>
                 
                        <a href="https://leapfrog.systems/" rel="noreferrer" target="_blank">
                                        <LeapfrogImage/>
                                        </a>
                       
                    </div>  
                
               
                        
                 
                </Col>
                </Row> 
                <Row>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: '10px'}}>
                        <h5 className='text-bg'>The Solana Validator Leaderboard</h5>
                        
                    </div>  
                </Row>
                <Row>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: '5px'}}>
                        
                        <h6 className='text-bg' style={{ textAlign: 'center'}} >See the best Solana Validators to stake with in terms of their APY</h6>
                    </div>  
               
                </Row>
      
            </Card>
        </div>
    </header>
}
export default MainNavigation;