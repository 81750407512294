import { useState, useEffect } from 'react';
import Moment from 'moment';

export default function DisplaySolPrice() {
    const [price, setPrice] = useState('0');
    const [percentageChange, setPercentageChange] = useState('0');

  useEffect(() => {
      const interval = setInterval(() => {
          const yestdate = Moment().subtract(1, 'days').format('YYYY-MM-DD');
          // console.log(yestdate);
          Promise.all([
              fetch("https://api.coinbase.com/v2/prices/SOL-USD/spot"),
              fetch("https://api.coinbase.com/v2/prices/SOL-USD/spot?q=&date=" + yestdate.toString())
          ])
              .then(results => Promise.all(results.map(r => r.json())))  
              .then(results => {
                  let price = Math.round(results[0].data.amount * 100) / 100;
                  let previous = results[1].data.amount;
                  let diff = Math.round(((price - previous) / price) * 1000) / 10;
                  setPrice(() => price);
                  setPercentageChange(() => diff);

              }       
            ).catch((err) => {
            console.log(err);
        });


       
        

    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div style={{
        display: 'flex',
        alignItems: 'center',
            }} className="sol-price">
      <b>◎ Price:</b>&nbsp; ${price} (Day:&nbsp;{percentageChange > 0? ['+',percentageChange] : percentageChange}%)
    </div>
  );
}